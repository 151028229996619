import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, ButtonBase } from "@material-ui/core"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import FacebookIcon from "@material-ui/icons/Facebook"
import YouTubeIcon from "@material-ui/icons/YouTube"
import PublicIcon from "@material-ui/icons/Public"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"

const useStyles = makeStyles(theme => ({
  cardContainer: {
    marginBottom: "10rem",
    textAlign: "center",
  },
  videoContainer: {
    marginTop: "2rem",
    "& iframe": {
      width: "100% !important",
      height: "25rem !important",
    },
  },
  imageContainer: {
    overflow: "hidden",
    margin: "2rem auto 0 auto",
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
  },
  image: {
    "& picture img": {
      objectFit: "contain !important",
    },
  },
  iconContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: "1rem",
  },
  title: {
    fontWeight: "700",
  },
  textContainer: {
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "2rem",
    },
  },
}))

const WhagwaanCard = ({ card, index }) => {
  const classes = useStyles()
  const isVideo = "youtubeEmbed" in card
  return isVideo ? (
    <Grid container classes={{ root: classes.cardContainer }}>
      <Grid item xs={12} sm={6} classes={{ root: classes.textContainer }}>
        <Typography
          variant="h5"
          component="h5"
          classes={{ root: classes.title }}
        >
          {card.title}
        </Typography>
        <Typography variant="body1" component="span">
          <MDXRenderer>{card.description.childMdx.body}</MDXRenderer>
        </Typography>
        <div className={classes.iconContainer}>
          {card.facebook !== "/" && (
            <ButtonBase href={card.facebook}>
              <FacebookIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.instagram !== "/" && (
            <ButtonBase href={card.instagram}>
              <InstagramIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.youtube !== "/" && (
            <ButtonBase href={card.youtube}>
              <YouTubeIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.website !== "/" && (
            <ButtonBase href={card.website}>
              <PublicIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.twitter !== "/" && (
            <ButtonBase href={card.twitter}>
              <TwitterIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
        </div>
        <div
          className={classes.videoContainer}
          dangerouslySetInnerHTML={{ __html: card.youtubeEmbed }}
        ></div>
      </Grid>
    </Grid>
  ) : (
    <Grid container classes={{ root: classes.cardContainer }}>
      <Grid item xs={12} sm={6} classes={{ root: classes.textContainer }}>
        <Typography
          variant="h5"
          component="h5"
          classes={{ root: classes.title }}
        >
          {card.title}
        </Typography>
        <Typography variant="body1" component="span">
          <MDXRenderer>{card.description.childMdx.body}</MDXRenderer>
        </Typography>
        <div className={classes.iconContainer}>
          {card.facebook !== "/" && (
            <ButtonBase href={card.facebook}>
              <FacebookIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.instagram !== "/" && (
            <ButtonBase href={card.instagram}>
              <InstagramIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.youtube !== "/" && (
            <ButtonBase href={card.youtube}>
              <YouTubeIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.website !== "/" && (
            <ButtonBase href={card.website}>
              <PublicIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
          {card.twitter !== "/" && (
            <ButtonBase href={card.twitter}>
              <TwitterIcon classes={{ root: classes.icon }} />
            </ButtonBase>
          )}
        </div>
        <div className={classes.imageContainer}>
          <Image fluid={card.image.fluid} className={classes.image} />
        </div>
      </Grid>
    </Grid>
  )
}

export default WhagwaanCard
