import React from "react"
import Bounce from "react-reveal/Bounce"
import WhagwaanCard from "./WhagwaanCard"

const index = ({ allCards }) => {
  return (
    <>
      {allCards.map((card, index) => (
        <Bounce right key={index}>
          <WhagwaanCard card={card} index={index} />
        </Bounce>
      ))}
    </>
  )
}

export default index
