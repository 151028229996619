import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/Seo/"
import Section from "../components/Layout/Section"
import MainLayout from "../components/Layout/MainLayout"
import Whagwaan from "../components/Whagwaan/"

export const data = graphql`
  query getWhagwaanContent {
    hero: contentfulHeroImages {
      whagwaan {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulWhagwaanImage(sort: { fields: createdAt, order: DESC }) {
      images: nodes {
        description {
          childMdx {
            body
          }
        }
        facebook
        id
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        instagram
        title
        twitter
        website
        youtube
      }
    }
    allContentfulWhagwaanVideo(sort: { fields: createdAt, order: DESC }) {
      videos: nodes {
        description {
          childMdx {
            body
          }
        }
        facebook
        id
        instagram
        title
        twitter
        website
        youtube
        youtubeEmbed
      }
    }
  }
`

const WhagwaanPage = ({ location, data }) => {
  const imageCards = data.allContentfulWhagwaanImage.images
  const videoCards = data.allContentfulWhagwaanVideo.videos
  const allCards = imageCards.concat(videoCards)
  const {
    hero: {
      whagwaan: { fluid },
    },
  } = data
  return (
    <MainLayout location={location} imageSrc={fluid} title="Wha'gwaan">
      <SEO title="Wha'gwaan" />
      <Section title="News & Support">
        <Whagwaan allCards={allCards} />
      </Section>
    </MainLayout>
  )
}

export default WhagwaanPage
